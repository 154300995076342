// extracted by mini-css-extract-plugin
export var animate = "index-module--animate--qH0jm";
export var button = "index-module--button--11jy-";
export var button3 = "index-module--button3--yRblt";
export var captionDiv = "index-module--captionDiv--MaAgp";
export var cardContentDiv = "index-module--cardContentDiv--mgtVh";
export var cardDiv = "index-module--cardDiv--GKTd8";
export var cardImgDiv = "index-module--cardImgDiv--XQ0Ew";
export var cardImgDiv1 = "index-module--cardImgDiv1--cxSOS";
export var cardSlotDiv = "index-module--cardSlotDiv--K8J+e";
export var cardSlotDiv1 = "index-module--cardSlotDiv1--vXw8Z";
export var cardsRowDiv = "index-module--cardsRowDiv--qVKUI";
export var cardsSectionDiv = "index-module--cardsSectionDiv--egn8a";
export var catCardRowDiv = "index-module--catCardRowDiv--mH+XI";
export var catCardSlotDiv = "index-module--catCardSlotDiv--PEhqM";
export var categoriesSectionDiv = "index-module--categoriesSectionDiv--ddz9Z";
export var categoryCardsDiv = "index-module--categoryCardsDiv--LBnpH";
export var ccsDiv = "index-module--ccsDiv--YpQIi";
export var contactContentDiv = "index-module--contactContentDiv--2pwj5";
export var contactDetailsDiv = "index-module--contactDetailsDiv---+AEL";
export var contactDetailsSignInDiv = "index-module--contactDetailsSignInDiv--8cebJ";
export var contactFormDiv = "index-module--contactFormDiv--CpgHf";
export var contactFormInputsDiv = "index-module--contactFormInputsDiv--3nBXg";
export var contactSectionDiv = "index-module--contactSectionDiv--nHJXd";
export var depositphotos349498906XL1Icon = "index-module--depositphotos349498906XL1Icon--AgrEF";
export var depositphotos349498906XL1Icon1 = "index-module--depositphotos349498906XL1Icon1--gd85t";
export var detailsDiv = "index-module--detailsDiv--jfbfu";
export var displayB = "index-module--displayB--m0f3K";
export var displayDiv = "index-module--displayDiv--y+4T0";
export var displayDiv1 = "index-module--displayDiv1--WdjPS";
export var displayDiv2 = "index-module--displayDiv2--euylP";
export var displayH2 = "index-module--displayH2--qwa95";
export var displayH21 = "index-module--displayH21--oW8RK";
export var errorMsg = "index-module--errorMsg--3-NQj";
export var featureDiv = "index-module--featureDiv--LvZKn";
export var featureLabelDiv = "index-module--featureLabelDiv--5RQWv";
export var featureSlotDiv = "index-module--featureSlotDiv--P273T";
export var featuresDiv = "index-module--featuresDiv--u1Fsa";
export var featuresRowDiv = "index-module--featuresRowDiv--JGyng";
export var formTitleDiv = "index-module--formTitleDiv--JL6Ii";
export var fullNameDiv = "index-module--fullNameDiv--1SkdR";
export var heroActionsDiv = "index-module--heroActionsDiv--xqhf8";
export var heroContentDiv = "index-module--heroContentDiv--3fxDx";
export var heroImgLDiv = "index-module--heroImgLDiv--v-zAA";
export var heroSectionDiv = "index-module--heroSectionDiv--qrQzw";
export var heroTextDiv = "index-module--heroTextDiv--XRr5n";
export var homeDiv = "index-module--homeDiv--3FHiQ";
export var iconBoxDiv = "index-module--iconBoxDiv--E7oH7";
export var imgIcon = "index-module--imgIcon--BcRMz";
export var input = "index-module--input--LwSTH";
export var inputFieldWIthLabelDiv = "index-module--inputFieldWIthLabelDiv--p30b-";
export var inputLabelDiv = "index-module--inputLabelDiv--5I6qF";
export var inputTextarea = "index-module--inputTextarea--knWWn";
export var label = "index-module--label--3A6AZ";
export var label2 = "index-module--label2--M+jJ9";
export var labelDiv = "index-module--labelDiv--XQSf7";
export var labelDiv8 = "index-module--labelDiv8--uUKAI";
export var labelDiv9 = "index-module--labelDiv9--Rg3Cp";
export var medicineSyrupIcon = "index-module--medicineSyrupIcon--5Amof";
export var pDiv = "index-module--pDiv--ntbG2";
export var patternDiv = "index-module--patternDiv--e1cBQ";
export var patternIcon = "index-module--patternIcon--LJZrP";
export var sectionTitleDiv = "index-module--sectionTitleDiv--m0xiz";
export var signInBtnDiv = "index-module--signInBtnDiv--dh-0z";
export var slideInTop = "index-module--slide-in-top--4H2u1";
export var titleDiv = "index-module--titleDiv--bQ+ji";
export var titleDiv1 = "index-module--titleDiv1--Ri5o4";
export var udshSymbol1Icon = "index-module--udshSymbol1Icon--ewglo";
export var visaIcon = "index-module--visaIcon--fsYqB";
export var visibleDetailsDiv = "index-module--visibleDetailsDiv--N+APc";
export var whySectionDiv = "index-module--whySectionDiv--b3aD0";