import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Button, Link
} from "@chakra-ui/react";
import { graphql, navigate } from "gatsby";
import CategoryCard from "../components/category-card";
import * as styles from "./index.module.css";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import ContactForm from "../components/Contact/ContactForm";
import useAuth from "../components/useAuth";


const IndexPage: FunctionComponent = () => {
  const { loggedIn, user } = useAuth();
  // const onButtonClick = useCallback(() => {
  //   navigate("/about-us");
  // }, []);
  // const onButton2Click = useCallback(() => {
  //   navigate("/shop");
  // }, []);

  const onButton4Click = useCallback(() => {
    navigate("/log-in");
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <Layout>
      <div className={styles.homeDiv}>
        <div className={styles.heroSectionDiv} data-animate-on-scroll>
          <img className={styles.patternIcon} alt="" src="/pattern.svg" />
          <div className={styles.heroImgLDiv}>
            <img className={styles.imgIcon} alt="" src="/img@2x.png" />
          </div>
          <div className={styles.heroContentDiv}>
            <div className={styles.heroTextDiv}>
              <div className={styles.titleDiv}>
                <b className={styles.displayB}>Welcome to UDS Supply</b>
              </div>
              <div className={styles.pDiv}>
                <div className={styles.captionDiv}>
                  Affordable Solutions for all of Your Medical Product Needs
                </div>
              </div>
            </div>
            <div className={styles.heroActionsDiv}>
              <Link href="/about-us" _hover={{ textDecoration:"none"}}>
              <Button
                className={styles.button}
                variant="outline"
                color="#2D3748"
                size="lg"
              >
                About Us
              </Button>
              </Link>
              <Link href="/shop" _hover={{ textDecoration:"none"}}>
              <Button
                variant="solid"
                colorScheme="gcsblue"
                size="lg"
                // onClick={onButton2Click}
              >
                Start Shopping
              </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.cardsSectionDiv}>
          <div className={styles.sectionTitleDiv}>
            <h2 className={styles.displayH2}>What We Offer</h2>
          </div>
         
          <div className={styles.cardsRowDiv}>
            <div className={styles.cardSlotDiv}>
              <div className={styles.cardDiv}>
                <div className={styles.cardContentDiv}>
                  <div className={styles.titleDiv}>
                    <b className={styles.label}>
                      A one stop solution for all your medical needs
                    </b>
                  </div>
                  <Link href="/about-us" _hover={{ textDecoration:"none"}}>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="gcsblue"
                    // onClick={onButtonClick}
                  >
                    Learn More
                  </Button>
                  </Link>
                </div>
                <div className={styles.cardImgDiv}>
                  <img
                    className={styles.depositphotos349498906XL1Icon}
                    alt=""
                    src="/depositphotos-349498906-xl-1@2x.png"
                  />
                </div>
              </div>
            </div>
           
            <div className={styles.cardSlotDiv1}>
              <div className={styles.cardDiv}>
                <div className={styles.cardContentDiv}>
                  <div className={styles.titleDiv}>
                    <b className={styles.label}>Wholesale Medical Equipment</b>
                  </div>
                  <Link href="/about-us" _hover={{ textDecoration:"none"}}>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="gcsblue"
                    // onClick={onButtonClick}
                  >
                    Learn More
                  </Button>
                  </Link>
                </div>
                <div className={styles.cardImgDiv1}>
                  <img
                    className={styles.depositphotos349498906XL1Icon1}
                    alt=""
                    src="/depositphotos-349498906-xl-11@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.categoriesSectionDiv}>
          <div className={styles.sectionTitleDiv}>
            <div className={styles.displayDiv}>Product Categories</div>
          </div>
          <div className={styles.categoryCardsDiv}>
            <div className={styles.catCardRowDiv}>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard
                  image="/image@2x.png"
                  label={`Clinic & Private Practice`}
                />
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard image="/image1@2x.png" label="Secondary Items" />
              </div>
              <div className={styles.catCardSlotDiv}>
              <Link href="/category/practice-instruments" w="full" _hover={{ textDecoration:"none"}}>
                <CategoryCard
                  image="/image2@2x.png"
                  label="Clinic Instruments"
                />
              </Link>  
              </div>
            </div>
            <div className={styles.catCardRowDiv}>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard
                  image="/image3@2x.png"
                  label="Personal Protective Instruments"
                />
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard image="/image4@2x.png" label="Home Careline"/>
              </div>
              <div className={styles.catCardSlotDiv}>
                <CategoryCard image="/image5@2x.png" label="Special Orders"/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whySectionDiv}>
          <div className={styles.sectionTitleDiv}>
            <h2 className={styles.displayH21}>Why Us</h2>
          </div>
          <div className={styles.featuresDiv}>
            <div className={styles.featuresRowDiv}>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/medicinesyrup.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Large Product Range</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/premiumrights.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Low Prices</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/ship.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Fast Delivery</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featuresRowDiv}>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/shieldcheck.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Trusted Brands</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/headset.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Customer Service</div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlotDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.iconBoxDiv}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="/world.svg"
                    />
                  </div>
                  <div className={styles.featureLabelDiv}>
                    <div className={styles.labelDiv}>Global Reach</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.contactSectionDiv}
          id="contactSectionContainer"
          data-scroll-to="contactSectionContainer"
        >
          <ContactForm />
          <div className={styles.contactDetailsSignInDiv}>
            <div className={styles.patternDiv}>
              <img
                className={styles.udshSymbol1Icon}
                alt=""
                src="/udshsymbol-1.svg"
              />
            </div>
            <div className={styles.contactDetailsDiv}>
              <div className={styles.titleDiv1}>
                <div className={styles.displayDiv2}>Need Anything?</div>
              </div>
              <div className={styles.visibleDetailsDiv}>
                <div className={styles.detailsDiv}>
                  <div className={styles.labelDiv}>Contact us:</div>
                  <b className={styles.label2}><Link href="tel:1888-207-7210">+1888-207-7210</Link></b>
                </div>
                <div className={styles.detailsDiv}>
                  <div className={styles.labelDiv}>Monday to Friday</div>
                  <b className={styles.label2}>9AM - 5PM EST</b>
                </div>
              </div>
              <div className={styles.signInBtnDiv}>
              {!loggedIn ?
                  (<Button
                    className={styles.button3}
                    variant="solid"
                    colorScheme="gcsblue"
                    onClick={onButton4Click}
                  >
                    Sign In
                  </Button>) :
                  (<Button className={styles.button3} variant ="solid" colorScheme="gcsblue">
                    <Link href="/acc-settings" _hover={ {textDecoration:"none" }}>{user?.firstName == null ? "Customer" : user.firstName}</Link>
                  </Button>)
                }
                
              </div>
              <div className={styles.labelDiv8}>
                {!loggedIn ?
                (<div className={styles.labelDiv9}>
                  Please sign in for address
                </div>) :
                (<div className={styles.labelDiv9}>
                  UDS Health Supply
                </div>)  
              } 
              
              </div>
              <div className={styles.ccsDiv}>
                <img className={styles.visaIcon} alt="" src="/visa.svg" />
                <img className={styles.visaIcon} alt="" src="/mastercard.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO title="UDS Supply" description="Home" />
)