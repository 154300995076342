import * as styles from '../../pages/index.module.css'
// React
import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { PhoneIcon } from "@chakra-ui/icons";


//Chakra UI
import {
  Input,
  Textarea,
  Button,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";


export default function ContactForm() {

var toast = useToast();

const [state, handleSubmit] = useForm("xrgvbgdz");

if (state.succeeded) {
  toast({
    status: "success",
    title: "Message was sent!",
  });
}

//initial values + setting new ones by typing in input
const [formValues, setFormValues] = useState({
  fullName: "",
  phone: "",
  email: "",
  message: "",
});
//error fields
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);

const handleChange = (input: any) => (e: any) => {
  const value = e.currentTarget.value;

  setFormValues((prevState) => ({
    ...prevState,
    [input]: value,
  }));
};

//form validation
const handleFormData = (event: React.FormEvent<HTMLFormElement>) => {
  // const [state, handleSubmit] = useForm("xrgvbgdz");
  event.preventDefault();

  setFormErrors(validate(formValues));
  setIsSubmit(true);
};

useEffect(() => {
  console.log(formErrors);
  if (Object.keys(formErrors).length === 0 && isSubmit) {
    // useForm("xrgvbgdz")
  }
}, [formErrors]);

const validate = (values: any) => {
  type errorType = {
    fullName?: string;
    message?: string;
  };
  
};


return (
    <form className={styles.contactContentDiv} onSubmit={handleSubmit}>
    <div className={styles.contactFormDiv}>
      <div className={styles.contactFormInputsDiv}>
        <div className={styles.formTitleDiv}>
          <div className={styles.displayDiv1}>Contact Us</div>
        </div>
        <div className={styles.inputFieldWIthLabelDiv}>
          <div className={styles.inputLabelDiv}>
            <div className={styles.fullNameDiv}>Full Name</div>
          </div>
          <Input
            id="fullName"
            name="fullName"
            className={styles.input}
            variant="outline"
            size="lg"
            placeholder="Your full name"
            type="text"
            required
            
          />
          <ValidationError
            prefix="fullName"
            field="fullName"
            errors={state.errors}
          />
        </div>
        <div className={styles.inputFieldWIthLabelDiv}>
          <div className={styles.inputLabelDiv}>
            <div className={styles.fullNameDiv}>Phone Number</div>
          </div>
          <InputGroup className={styles.input}>
            <InputLeftElement
              pointerEvents="none"
              children={<PhoneIcon color="gray.300" mt="6px" />}
            />
            <Input
              id="phone"
              name="phone"
              variant="outline"
              size="lg"
              placeholder="00 38 69 123 456 789"
              required
              defaultValue={formValues.phone}
              onChange={handleChange("phone")}
            />
          </InputGroup>
        </div>
        <div className={styles.inputFieldWIthLabelDiv}>
          <div className={styles.inputLabelDiv}>
            <div className={styles.fullNameDiv}>Email</div>
          </div>
          <Input
            id="email"
            type="email"
            name="email"
            className={styles.input}
            variant="outline"
            size="lg"
            placeholder="example@email.com"
            required
            defaultValue={formValues.email}
            onChange={handleChange("email")}
          />
          <div className={styles.errorMsg}>
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
          />
          </div>
        </div>
        <div className={styles.inputFieldWIthLabelDiv}>
          <div className={styles.inputLabelDiv}>
            <div className={styles.fullNameDiv}>Message</div>
          </div>
          <Textarea
            id="message"
            name="message"
            className={styles.inputTextarea}
            variant="outline"
            size="lg"
            placeholder="Text Area"
            required
            defaultValue={formValues.message}
            onChange={handleChange("message")}
          />
          <ValidationError
            prefix="Message"
            field="Message"
            errors={state.errors}
          />
        </div>
        <div className="g-recaptcha" data-sitekey="6LcrnwAkAAAAAKwNQXCKM1-ZJQcMDEf17JCgzXR4"></div> 
              <Button className={styles.button3} variant="solid" colorScheme="gcsblue" type="submit" mt="2"> Send Message </Button>
      </div>
    </div>
  </form>
)
}
